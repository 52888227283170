import axios from "axios";
import { statusCode } from "../util/ApiConstant";
import { serverUrl } from "../util/ApiConstant";
import { apiMethods } from "../util/ApiConstant";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const navigate = (path) => {
  window.location.href = `${window.location.origin}/${path}`;
};

export const getAuthToken= ()=>{
  return Cookies.get('news_token');
}

export const axiosInstance = axios.create({
  baseURL: serverUrl,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${getAuthToken()}`
  },
})

axiosInstance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (request) => {
    return request;
  },
  (error) => {
    if (error?.response?.status === statusCode.internal_server_error) {
      console.log("===", error?.response)
      // navigate("network-error"); 
    } 
    else if(error?.message === "Network Error"){
      console.log("===", error?.message)
      // navigate("network-error");
    }
    else if (error?.response?.status === statusCode.unauthorized_user) {
      toast.error("Please Login",{onClose: ()=>{
        navigate("admin");
      }});
    }
    return {data:error.response?.data,status:error.response?.status};
  }
);

export const apiCall = async (params) => {
  // eslint-disable-next-line default-case
  switch (params.method) {
    case apiMethods.GET: {
      const response = await axiosInstance({
        method: params.method,
        url: params.endPoint,
      });
      return {data:response?.data,status:response?.status};
    }
    case apiMethods.POST: {
      const response = await axiosInstance({
        method: params.method,
        url: params.endPoint,
        data: params.data,
      });
      return {data:response?.data,status:response?.status};
    }
    case apiMethods.PUT: {
      const response = await axiosInstance({
        method: params.method,
        url: params.endPoint,
        data: params.data,
      });
      return {data:response?.data,status:response?.status};
    }
  }
};

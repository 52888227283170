export const serverUrl = "https://api.maayamnews.com/api";

export const apiMethods = {
  POST: "POST",
  GET: "GET",
  PATCH: "PATCH",
  PUT: "PUT",
};

export const statusCode = {
  success: 200,
  bad_request: 400,
  not_found: 404,
  internal_server_error: 500,
  unauthorized_user: 401,
  inserted: 201,
};

export const apiEndPoints = {
  blogPost: "/blog-post",
  category: "/category",
  like: "/blog-like",
  login: "/login",
  tags: "/tags",
};

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Category from "../category/Category";
import { apiMethods } from "../../util/ApiConstant";
import { apiEndPoints } from "../../util/ApiConstant";
import { apiCall } from "../../service/ApiHelper";
import { CircularProgress } from "@mui/material";
import { statusCode } from "../../util/ApiConstant";
import CardLayout from "../cardLayout/CardLayout";

function TagShower() {
  const [tagBlog, setTagBlog] = useState([]);
  const [loading, setLoading] = useState(true);

  const path = window.location.pathname.split("/");
  const tagId = path[path.length - 1];

  const getPost = async () => {
    const response = await apiCall({
      method: apiMethods.GET,
      endPoint: `${apiEndPoints.blogPost}?tagId=${tagId}`,
    });
    if (response?.status === statusCode.success) {
      setTagBlog(response?.data?.data ?? []);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    <CircularProgress />;
  }

  return (
    <>
      <Category
        arrow={true}
        type={tagBlog[0]?.blog_post_tags[0]?.tag?.tag ?? ""}
      />

      <Grid container mt={2} xs={12} md={12} justifyContent={"space-evenly"}>
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          md={12}
          sx={{ marginBottom: "20px" }}
        >
          {tagBlog &&
            tagBlog.map((d) => {
              return (
                <Grid md={5}>
                  <CardLayout
                    url={d.post_contents[0]?.photoURL}
                    content={d.title}
                    id={d.post_contents[0]?.postId}
                    publishDate={d.createdAt}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
}

export default TagShower;

import React from "react";
import { Grid, Typography } from "@mui/material";

function Contact() {
  return (
    <Grid sx={{ padding: "20px" }}>
      <Typography variant="h5">Contact Us:</Typography>
      <Typography variant="p">
        You may contact us via e-mail for any queries at all. We review all the
        communications we receive. Unfortunately, due to the large number of
        correspondence we receive, we would only respond to e-mails when there
        is a need
      </Typography>

      <br />
      <br />
      <Typography variant="p">Email: maayamnews@gmail.com</Typography>
    </Grid>
  );
}

export default Contact;

import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

function Category({ type, id, arrow = true }) {
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate(`/category/${id}`);
  };
  return (
    <Grid md={6}>
      {/* <IconButton onClick={arrow ? navigateHandler : null} sx={{backgroundColor:"#ff0000",width:"30%",borderRadius:"10px",color:"#fff",marginBottom:"5px",fontWeight:"bold"}}> */}
      {arrow && (
        <Grid
          sx={{
            backgroundColor: "#ff0000",
            width: "40%",
            height: "40px",
            color: "#fff",
            marginBottom: "5px",
            fontWeight: "bold",
          }}
        >
          <Typography sx={{ padding: "8px" }}>{type}</Typography>
        </Grid>
      )}
      {/* {arrow && <ArrowForwardIcon/>} */}
      {/* </IconButton> */}
    </Grid>
  );
}

export default Category;

import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const navigateHandler = (path) => {
    navigate(`/${path}`);
  };
  return (
    <footer style={{ textAlign: "center" }}>
      <div style={{ color: "#666" }}>
        {" "}
        <a
          href="https://www.facebook.com/profile.php?id=61553856676152&mibextid=LQQJ4d"
          target="_blank"
          className="fa fa-facebook"
        ></a>
        <a
          href="https://instagram.com/maayamnews"
          target="_blank"
          className="fa fa-instagram"
        ></a>
        <a
          href="https://twitter.com/maayamnews"
          target="_blank"
          className="fa fa-twitter"
        ></a>
        <a
          href="https://www.youtube.com/@Maayamnewstamil"
          target="_blank"
          className="fa fa-youtube"
        ></a>
        <a
          href="https://whatsapp.com/channel/0029Va9bEeI0bIdv3THcnq0b"
          target="_blank"
          className="fa fa-whatsapp"
        ></a>
        <a
          href="https://t.me/maayamnews"
          target="_blank"
          className="fa fa-telegram"
        ></a>
      </div>
      <Grid
        sx={{
          border: "1px #ccc solid",
          width: "100%",
          marginTop: "25px",
          textAlign: "center",
          padding: "10px 0 10px 0",
          marginBottom: "25px",
        }}
      >
        <span
          style={{ cursor: "pointer", color: "#666" }}
          onClick={() => navigateHandler("about")}
        >
          About Us
        </span>{" "}
        |{" "}
        <span
          style={{ cursor: "pointer", color: "#666" }}
          onClick={() => navigateHandler("terms")}
        >
          Terms and Condition
        </span>{" "}
        |{" "}
        <span
          style={{ cursor: "pointer", color: "#666" }}
          onClick={() => navigateHandler("privacy")}
        >
          Privacy Policy
        </span>{" "}
        |{" "}
        <span
          style={{ cursor: "pointer", color: "#666" }}
          onClick={() => navigateHandler("contact")}
        >
          Contact Us
        </span>
        <div style={{ color: "#666" }}>
          <br />
          &copy; 2024 Maayam.in. All Rights Reserved.
          <br />
          <br /> The "MaayamNews" word mark and logo are owned by Maayam.in
        </div>
      </Grid>
    </footer>
  );
}

export default Footer;

import React from "react";
import { Grid, Typography } from "@mui/material";

function About() {
  return (
    <Grid sx={{ padding: "20px" }}>
      <Typography variant="h5">About Maayam news</Typography>
      <br />
      <br />
      <Typography variant="p">
        Welcome to [Your News Website] At Maayam news, we believe in the power
        of information to enlighten, inspire, and empower. Our mission is to
        deliver timely, accurate, and insightful news coverage that informs our
        readers and encourages meaningful dialogue in our communities.
        <br />
        <br />
        Established in 2024, Maayam news has quickly become a trusted source for
        breaking news, in-depth analysis, and thought-provoking commentary on a
        wide range of topics, from politics and economics to culture and
        technology.
        <br /> <br />
        Our team of experienced journalists and contributors are dedicated to
        upholding the highest standards of journalism, adhering to principles of
        integrity, impartiality, and accountability in our reporting. We strive
        to present diverse perspectives and voices, ensuring that our readers
        are well-informed and equipped to navigate the complexities of the
        modern world.
        <br /> <br />
        At Maayam news, we recognize the importance of engaging with our
        audience and fostering a sense of community. That's why we encourage
        feedback, discussion, and constructive criticism from our readers. We
        believe that journalism is a collaborative endeavor, and we're committed
        to listening to our readers and continuously improving our coverage.
        <br /> <br />
        Whether you're looking for the latest headlines, in-depth analysis, or
        insightful commentary, you'll find it all here at Maayam news. Thank you
        for joining us on this journey, and we look forward to serving you with
        the news and information you need to stay informed and engaged.
      </Typography>
    </Grid>
  );
}

export default About;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import General from "./components/general/General";
import NewsShower from "./pages/newsShower/NewsShower";
import AdminLogin from "./pages/adminLogin/AdminLogin";
import CreateBlog from "./pages/createBlog/CreateBlog";
import CategoryShower from "./components/CategoryShower/CategoryShower";
import TagShower from "./components/tagshower/TagShower";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NetWorkError from "./pages/NetworkError/NetWorkError";
import About from "./components/aboutus/About";
import TermsCondition from "./components/termsAndCondition/TermsCondition";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import Contact from "./components/contact/Contact";
import { Helmet } from "react-helmet";
import logo from "./asserts/maayamLogo.jpg";

function App() {
  const generalLayout = (component) => {
    return <General>{component}</General>;
  };

  return (
    <div>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="Maayam news" />
        <meta property="og:description" content="Maayam news" />
        <meta property="og:image" content={logo} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content="Maayam news" />
        <meta property="twitter:description" content="Maayam news" />
      </Helmet>
      <Router>
        <Routes>
          <Route exact path="/" element={generalLayout(<Home />)}></Route>
          <Route exact path="/admin" element={<AdminLogin />}></Route>
          <Route exact path="/create-blog" element={<CreateBlog />}></Route>
          <Route
            exact
            path="/blog-post/:id"
            element={generalLayout(<NewsShower />)}
          ></Route>
          <Route
            exact
            path="/category/:categoryId"
            element={generalLayout(<CategoryShower />)}
          ></Route>
          <Route
            exact
            path="/tag/:tagId"
            element={generalLayout(<TagShower />)}
          ></Route>
          <Route exact path="/network-error" element={<NetWorkError />}></Route>
          <Route exact path="/about" element={generalLayout(<About />)}></Route>
          <Route
            exact
            path="/privacy"
            element={generalLayout(<PrivacyPolicy />)}
          ></Route>
          <Route
            exact
            path="/terms"
            element={generalLayout(<TermsCondition />)}
          ></Route>
          <Route
            exact
            path="/contact"
            element={generalLayout(<Contact />)}
          ></Route>
        </Routes>
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;

import { Typography,Grid } from '@mui/material'
import React from 'react'

function NetWorkError() {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
        <Typography>Check Your Network Connection</Typography>
    </Grid>
  )
}

export default NetWorkError;
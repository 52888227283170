import React, { useEffect, useState } from "react";
import CardLayout from "../../components/cardLayout/CardLayout";
import Category from "../../components/category/Category";
import Grid from "@mui/material/Grid";
import { apiCall } from "../../service/ApiHelper";
import { apiEndPoints, apiMethods, statusCode } from "../../util/ApiConstant";
import { CircularProgress } from "@mui/material";

function Home() {
  const [trendingBlog, setTrendingBlog] = useState([]);
  // const [worldBlog, setWorldBlog] = useState([]);
  // const [moviesBlog, setMoviesBlog] = useState([]);
  // const [sportsBlog, setsportsBlog] = useState([]);

  const [loading, setLoading] = useState(true);

  const trending = apiCall({
    method: apiMethods.GET,
    endPoint: `${apiEndPoints.blogPost}?trending=true`,
  });
  const world = apiCall({
    method: apiMethods.GET,
    endPoint: `${apiEndPoints.blogPost}?categoryId=14`,
  });

  const movies = apiCall({
    method: apiMethods.GET,
    endPoint: `${apiEndPoints.blogPost}?categoryId=12`,
  });

  const sports = apiCall({
    method: apiMethods.GET,
    endPoint: `${apiEndPoints.blogPost}?categoryId=13`,
  });

  const getPost = async () => {
    const response = await Promise.all([trending, world, movies, sports]);
    if (response[3]?.status === statusCode.success) {
      setTrendingBlog(response[0]?.data?.data ?? []);
      // setWorldBlog(response[1]?.data?.data ?? []);
      // setMoviesBlog(response[2]?.data?.data ?? []);
      // setsportsBlog(response[3]?.data?.data ?? []);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <div>
      <Grid md={6}>
        <Category
          arrow={true}
          type={"Trending"}
          id={trendingBlog[0]?.categoryId}
        />
      </Grid>
      <Grid container gap={3} xs={12} md={12} justifyContent={"space-evenly"}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          justifyContent={"space-between"}
          sx={{ marginBottom: "20px" }}
        >
          {trendingBlog &&
            trendingBlog.map((d) => {
              return (
                <Grid xs={12} md={5}>
                  <CardLayout
                    url={d.post_contents[0]?.photoURL}
                    content={d.title}
                    id={d.post_contents[0]?.postId}
                    publishDate={d.createdAt}
                  />
                </Grid>
              );
            })}
        </Grid>
        {/* <Grid xs={12} md={5} sx={{ marginBottom: "20px" }}>
          <Category type={"World"} id={worldBlog[0]?.categoryId} />
          {worldBlog &&
            worldBlog.map((d) => {
              return (
                <CardLayout
                  url={d.post_contents[0]?.photoURL}
                  content={d.title}
                  id={d.post_contents[0]?.postId}
                />
              );
            })}
        </Grid>
        <Grid xs={12} md={5} sx={{ marginBottom: "20px" }}>
          <Category type={"Movies"} id={moviesBlog[0]?.categoryId} />
          {moviesBlog &&
            moviesBlog.map((d) => {
              return (
                <CardLayout
                  url={d.post_contents[0]?.photoURL}
                  content={d.title}
                  id={d.post_contents[0]?.postId}
                />
              );
            })}
        </Grid>
        <Grid xs={12} md={5} sx={{ marginBottom: "20px" }}>
          <Category type={"Sports"} id={sportsBlog[0]?.categoryId} />
          {sportsBlog &&
            sportsBlog.map((d) => {
              return (
                <CardLayout
                  url={d.post_contents[0]?.photoURL}
                  content={d.title}
                  id={d.post_contents[0]?.postId}
                />
              );
            })}
        </Grid> */}
      </Grid>
    </div>
  );
}

export default Home;

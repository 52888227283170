import React from "react";
import { Grid, Typography } from "@mui/material";

function TermsCondition() {
  return (
    <Grid sx={{ padding: "20px" }}>
      <Typography variant="h5">Terms and Condition</Typography>
      <br />
      <br />
      <Typography variant="p">
        Welcome to Maayam News! These terms and conditions outline the rules and
        regulations for the use of the Maayam News website.
        <br />
        <br /> By accessing this website, we assume you accept these terms and
        conditions in full. Do not continue to use Maayam News if you do not
        accept all of the terms and conditions stated on this page.
        <br />
        <br /> The following terminology applies to these Terms and Conditions,
        Privacy Statement and Disclaimer Notice and any or all Agreements:
        "Client", "You" and "Your" refers to you, the person accessing this
        website and accepting the Company’s terms and conditions. "The Company",
        "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
        "Parties", or "Us", refers to both the Client and ourselves, or either
        the Client or ourselves. All terms refer to the offer, acceptance, and
        consideration of payment necessary to undertake the process of our
        assistance to the Client in the most appropriate manner, whether by
        formal meetings of a fixed duration, or any other means, for the express
        purpose of meeting the Client’s needs in respect of provision of the
        Company’s stated services/products, in accordance with and subject to,
        prevailing law of . Any use of the above terminology or other words in
        the singular, plural, capitalization and/or he/she or they, are taken as
        interchangeable and therefore as referring to same.
        <br />
        <br />
        We will approve link requests from these organizations if we determine
        that: (a) the link would not reflect unfavorably on us or our accredited
        businesses (for example, trade associations or other organizations
        representing inherently suspect types of business, such as work-at-home
        opportunities, shall not be allowed to link); (b)the organization does
        not have an unsatisfactory record with us; (c) the benefit to us from
        the visibility associated with the hyperlink outweighs the absence of ;
        and (d) where the link is in the context of general resource information
        or is otherwise consistent with editorial content in a newsletter or
        similar product furthering the mission of the organization.
        <br />
        <br />
        These organizations may link to our home page, to publications or to
        other website information so long as the link: (a) is not in any way
        misleading; (b) does not falsely imply sponsorship, endorsement or
        approval of the linking party and it products or services; and (c) fits
        within the context of the linking party's site.
        <br />
        <br />
        If you are among the organizations listed in paragraph 2 above and are
        interested in linking to our website, you must notify us by sending an
        e-mail to maayamnews@gmail.com. Please include your name, your
        organization name, contact information (such as a phone number and/or
        e-mail address) as well as the URL of your site, a list of any URLs from
        which you intend to link to our Web site, and a list of the URL(s) on
        our site to which you would like to link. Allow 2-3 weeks for a
        response.
      </Typography>
      <br />
      <br />
      <Typography variant="h5">Disclaimer</Typography>
      <br />
      <br />
      <Typography variant="p">
        Maayam news strives to provide accurate and reliable information to our
        readers. However, it's important to note that the content on this
        website is for informational purposes only and should not be considered
        as professional advice or a substitute for professional consultation.
        <br />
        <br />
        While we make every effort to ensure the accuracy and completeness of
        the information presented, we cannot guarantee that all content is
        error-free or up-to-date. News is constantly evolving, and updates or
        corrections may be necessary as new information becomes available.
        <br />
        <br />
        Opinions expressed in articles, commentaries, or user-generated content
        on Maayam news are those of the authors and do not necessarily reflect
        the views of the editorial team or the organization as a whole. We
        strive to present a diverse range of perspectives, but inclusion of any
        particular viewpoint does not imply endorsement.
        <br />
        <br />
        Maayam news may contain links to third-party websites or resources for
        the convenience of our readers. However, we do not endorse or take
        responsibility for the content, accuracy, or availability of these
        external sites.
        <br />
        <br />
        Readers are encouraged to independently verify information presented on
        Maayam news and to consult with qualified professionals for specific
        advice or guidance related to their individual circumstances. We
        recommend exercising critical thinking and discernment when consuming
        news and information from any source.
        <br />
        <br />
        By using Maayam news, you agree to abide by our terms of use and privacy
        policy. If you have any questions or concerns about the content on our
        website, please don't hesitate to contact us.
      </Typography>
    </Grid>
  );
}

export default TermsCondition;

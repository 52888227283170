import {
  Typography,
  Grid,
  Chip,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState, useEffect, useRef } from "react";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { Link, useLocation } from "react-router-dom";
import { apiMethods } from "../../util/ApiConstant";
import { apiEndPoints } from "../../util/ApiConstant";
import { statusCode } from "../../util/ApiConstant";
import { apiCall } from "../../service/ApiHelper";
import ShareUi from "../../components/shareUi/ShareUi";
import CardLayout from "../../components/cardLayout/CardLayout";
import Category from "../../components/category/Category";
import { Helmet } from "react-helmet";

function NewsShower() {
  const location = useLocation();
  const [blog, setBlog] = useState();
  const [like, setLike] = useState(true);
  const [disLike, setDislike] = useState(true);
  const [loading, setLoading] = useState(true);
  const firstRender = useRef(true);
  const [categoryBlog, setCategoryBlog] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false);

  const getCategoryPostById = async (categoryId) => {
    setDataNotFound(false);
    const response = await apiCall({
      method: apiMethods.GET,
      endPoint: `${apiEndPoints.blogPost}?categoryId=${categoryId}&pageLimit=10`,
    });
    if (response?.status === statusCode.success) {
      setCategoryBlog(response.data.data ?? []);
      setLoading(false);
    } else if (response?.status === statusCode.not_found) {
      setLoading(false);
      setDataNotFound(true);
    }
  };
  const getPost = async () => {
    setLoading(true);
    const response = await apiCall({
      method: apiMethods.GET,
      endPoint: location.pathname,
    });
    if (response?.status === statusCode.success) {
      setBlog(response?.data?.data);
      getCategoryPostById(response?.data?.data.categoryId);
      setLoading(false);
    } else if (response?.status === statusCode.not_found) {
      setLoading(false);
      setDataNotFound(true);
    }
  };

  const updateLike = async () => {
    if (like) {
      const response = await apiCall({
        method: apiMethods.PUT,
        endPoint: `${apiEndPoints.like}/${blog.id}`,
        data: { like: true },
      });
      if (response?.status === statusCode.success) {
        setLike(false);
        getPost();
      }
    }
  };
  const updateDisLike = async () => {
    if (disLike) {
      const response = await apiCall({
        method: apiMethods.PUT,
        endPoint: `${apiEndPoints.like}/${blog.id}`,
        data: { like: false },
      });
      if (response?.status === statusCode.success) {
        setDislike(false);
        getPost();
      }
    }
  };

  useEffect(() => {
    if (firstRender.current || location.pathname) {
      getPost();
      firstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const date = new Date(blog?.publishDate);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  const formattedDate = date.toLocaleString("en-IN", options);
  let checkIsMobile = useMediaQuery("(max-width:600px)");
  if (loading) {
    <Grid
      container
      height="100vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>;
  } else if (dataNotFound) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="Maayam news" />
        <meta property="og:description" content="Maayam news" />
        <meta property="og:image" content={blog.post_contents[0].photoURL} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content="Maayam news" />
        <meta property="twitter:description" content="Maayam news" />
        <meta
          property="twitter:image"
          content={blog.post_contents[0].photoURL}
        />
        <Typography>No Data Found</Typography>
      </Grid>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Maayam news" />
          <meta property="og:description" content={blog?.title} />
          <meta
            property="og:image"
            content={blog?.post_contents[0]?.photoURL}
          />

          <meta name="twitter:card" content="summary_large_image"></meta>

          <meta property="twitter:url" content={window.location.href} />
          <meta property="twitter:title" content="Maayam news" />
          <meta property="twitter:description" content={blog?.title} />
          <meta
            property="twitter:image"
            content={blog?.post_contents[0]?.photoURL}
          />
        </Helmet>
        <Grid container md={12} sx={{ backgroundColor: "#fff", padding: "2%" }}>
          {blog?.post_contents &&
            blog?.post_contents.length > 0 &&
            blog?.post_contents.map((post, index) => (
              <>
                {checkIsMobile && index === 0 ? (
                  <Typography sx={{ fontWeight: "bold", margin: "2%" }}>
                    {blog?.title}
                  </Typography>
                ) : null}
                {post.photoURL && post.photoURL !== "" && (
                  <Grid md={index === 0 ? 5 : 12}>
                    <img
                      width="100%"
                      height="300px"
                      style={{ objectFit: "fill" }}
                      src={post.photoURL}
                      alt="news app"
                    />
                  </Grid>
                )}
                {index === 0 && (
                  <Grid
                    md={5}
                    sx={{ display: checkIsMobile ? "block" : "block" }}
                  >
                    {!checkIsMobile ? (
                      <Typography sx={{ fontWeight: "bold", margin: "2%" }}>
                        {blog?.title}
                      </Typography>
                    ) : null}
                    {!checkIsMobile ? (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#4f4f4f",
                          margin: "2%",
                        }}
                      >
                        Created By: <ins>{blog?.user?.fullName}</ins>
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "#4f4f4f",
                            margin: "2%",
                          }}
                        >
                          Created By:{" "}
                          {/* <span style={{ color: "#ed2128", fontWeight: 800, }}>
                        <ins>{blog?.user?.fullName}</ins>
                      </span> */}
                          <span>{blog?.user?.fullName}</span>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#4f4f4f",
                            fontSize: "12px",
                            margin: "2%",
                          }}
                        >
                          Published:{" "}
                          <span style={{ color: "#949494", fontSize: "12px" }}>
                            {formattedDate}
                          </span>
                        </Typography>
                      </>
                    )}

                    <Grid margin={3}>
                      <ShareUi title={blog?.title} url={blog?.id} />
                    </Grid>
                  </Grid>
                )}
                <Grid md={12} sx={{ marginBottom: "4%" }}>
                  <Typography>{post.content}</Typography>
                </Grid>
              </>
            ))}
          <Grid container md={12} justifyContent="space-between">
            <Grid>
              <Chip
                icon={<VisibilityIcon />}
                label={`Views ${blog?.views ?? ""}`}
              />
            </Grid>
            <Grid sx={{ display: "flex" }} gap={2}>
              <Grid
                container
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconButton onClick={updateLike}>
                  <ThumbUpOffAltIcon />
                </IconButton>
                <Typography>{blog?.likes}</Typography>
              </Grid>
              <Grid
                container
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconButton onClick={updateDisLike}>
                  <ThumbDownOffAltIcon />
                </IconButton>
                <Typography>{blog?.dislikes}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid gap={1}>
            <Grid container gap={1} marginBottom={2}>
              tags:
              {blog?.blog_post_tags?.map((tag) => {
                return (
                  <Grid>
                    <Link to={`/tag/${tag.tagId}`}>{tag.tag.tag}</Link>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Typography
          sx={{ color: "#4f4f4f", fontSize: "20px", marginLeft: "10px" }}
        >
          More From Maayam News
        </Typography>
        <Grid container gap={3} xs={12} md={12} justifyContent={"space-evenly"}>
          <Category
            arrow={false}
            type={categoryBlog[0]?.category?.category ?? ""}
          />

          <Grid
            container
            justifyContent={"space-between"}
            xs={12}
            sm={12}
            md={12}
            gap={2}
            sx={
              checkIsMobile && {
                marginBottom: "20px",
                overflowX: "scroll",
                flexWrap: "nowrap",
              }
            }
          >
            {categoryBlog &&
              categoryBlog.map((d) => {
                return (
                  <Grid md={5.5} container flexDirection={"column"}>
                    <CardLayout
                      url={d.post_contents[0]?.photoURL}
                      content={d.title}
                      id={d.post_contents[0]?.postId}
                      publishDate={d.createdAt}
                      width="50vw"
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default NewsShower;

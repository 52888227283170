import {
  AppBar,
  List,
  Grid,
  Toolbar,
  CircularProgress,
  Popper,
  Paper,
  useMediaQuery,
  Backdrop,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { statusCode } from "../../util/ApiConstant";
import { apiMethods } from "../../util/ApiConstant";
import { apiEndPoints } from "../../util/ApiConstant";
import { apiCall } from "../../service/ApiHelper";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../asserts/maayamLogo.jpg";
import whatsapp from "../../asserts/whatsapp.png";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

function Navbar() {
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();
  let check = useMediaQuery("(max-width:600px)");

  const handleCloseOpenBackdrop = () => {
    setAnchorEl(null);
    setOpenBackdrop(!openBackdrop);
  };

  const getCategory = async () => {
    const response = await apiCall({
      method: apiMethods.GET,
      endPoint: apiEndPoints.category,
    });
    if (response?.status === statusCode.success) {
      setCategory(response?.data?.category ?? []);
      setLoading(false);
    } else if (response?.status === statusCode.not_found) {
      setCategory([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);
  const useNavStyle = {
    appBar: {
      backgroundColor: check ? "#fff" : "#fff",
    },
    list: {
      backgroundColor: "yellow",
      padding: "5px",
      marginLeft: "5px",
    },
    link: {
      textColor: "#fff",
      textDecoration: "none",
      zIndex: 3,
    },
  };

  const openNavModal = (event) => {
    setOpenBackdrop(!openBackdrop);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Backdrop
          open={openBackdrop}
          onClick={handleCloseOpenBackdrop}
        ></Backdrop>
        <div>
          <AppBar component="nav" sx={useNavStyle.appBar}>
            {!check ? (
              <Grid md={2}>
                <img
                  src={logo}
                  alt="Company Logo"
                  width={"100%"}
                  height={"130px"}
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{
                    cursor: "pointer",
                    objectFit: "contain",
                    backgroundColor: "#fff",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 5,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {/* <a
                    rel="noreferrer"
                    href="https://www.facebook.com/profile.php?id=61553856676152&mibextid=LQQJ4d"
                    target="_blank"
                  > */}

                  {/* <img style={{objectFit:"contain"}} width="30px" height="30px" src={facebook} alt="facebook"/> */}
                  {/* <Chip
                      avatar={<Avatar alt="Natacha" src={facebook} />}
                      variant="filled"
                    /> */}
                  {/* </a> */}
                  <a
                    href="https://www.facebook.com/profile.php?id=61553856676152&mibextid=LQQJ4d"
                    target="_blank"
                    className="fa fa-facebook"
                  ></a>
                  <a
                    href="https://instagram.com/maayamnews"
                    target="_blank"
                    className="fa fa-instagram"
                  ></a>
                  <a
                    href="https://twitter.com/maayamnews"
                    target="_blank"
                    className="fa fa-twitter"
                  ></a>
                  <a
                    href="https://www.youtube.com/@Maayamnewstamil"
                    target="_blank"
                    className="fa fa-youtube"
                  ></a>
                  <a
                    href="https://whatsapp.com/channel/0029Va9bEeI0bIdv3THcnq0b"
                    target="_blank"
                    className="fa fa-whatsapp"
                  ></a>
                  <a
                    href="https://t.me/maayamnews"
                    target="_blank"
                    className="fa fa-telegram"
                  ></a>

                  {/* <a
                    rel="noreferrer"
                    href="https://instagram.com/maayamnews"
                    target="_blank"
                  >
                    <img style={{objectFit:"contain"}} width="30px" height="30px" src={instagram} alt="instagram"/>
                    <Chip
                      avatar={<Avatar alt="Natacha" src={instagram} />}
                      variant="filled"
                    />
                  </a> */}
                  {/* <a
                    rel="noreferrer"
                    href="https://twitter.com/maayamnews"
                    target="_blank"
                  >
                    <img style={{objectFit:"contain"}} width="30px" height="30px" src={twitter} alt="twitter"/>
                    <Chip
                      avatar={<Avatar alt="Natacha" src={twitter} />}
                      variant="filled"
                    />
                  </a> */}
                  {/* <a
                    rel="noreferrer"
                    href="https://www.youtube.com/@MaayamNews"
                    target="_blank"
                  >
                    <img style={{objectFit:"contain"}} width="30px" height="30px" src={youtube} alt="youtube"/>
                    <Chip
                      avatar={<Avatar alt="Natacha" src={youtube} />}
                      variant="filled"
                    />
                  </a> */}
                  {/* <a
                    rel="noreferrer"
                    href="https://whatsapp.com/channel/0029Va9bEeI0bIdv3THcnq0b"
                    target="_blank"
                  >
                    <img style={{objectFit:"contain"}} width="30px" height="30px" src={whatsapp} alt="whatsapp"/>
                    <Chip
                      avatar={<Avatar alt="Natacha" src={whatsapp} />}
                      variant="filled"
                    />
                  </a> */}
                </div>
              </Grid>
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: 10,
                  right: 5,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <a
                  rel="noreferrer"
                  href="https://whatsapp.com/channel/0029Va9bEeI0bIdv3THcnq0b"
                  target="_blank"
                >
                  <Chip
                    avatar={<Avatar alt="Natacha" src={whatsapp} />}
                    label={"Join Channel"}
                    variant="filled"
                  />
                </a>
              </div>
            )}

            {/* <Grid sm={12} md={12} xs={12} width={"100%"} margin={"auto"}> */}
            <Grid container sm={12} md={12} xs={12} flexDirection={"row"}>
              <Grid container>
                {check && (
                  <Grid xs={2} sm={1} md={1}>
                    <IconButton onClick={openNavModal}>
                      {anchorEl ? (
                        <CloseIcon color={"primary"} />
                      ) : (
                        <MenuIcon color={"primary"} />
                      )}
                    </IconButton>
                  </Grid>
                )}
                <Grid
                  xs={10}
                  sm={"auto"}
                  md={"auto"}
                  ml={"-7%"}
                  justifyContent={"center"}
                >
                  {check ? (
                    <>
                      <img
                        src={logo}
                        alt="Company Logo"
                        width={"100%"}
                        height={"60px"}
                        onClick={(e) => {
                          navigate("/");
                        }}
                        style={{
                          cursor: "pointer",
                          objectFit: "contain",
                          backgroundColor: "#fff",
                        }}
                      />
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Popper
                sx={{
                  width: check ? "70%" : "100%",
                  overflowY: "auto",
                  maxHeight: "100dvh",
                  overflow: "scroll",
                  zIndex: check ? 200000 : 0,
                }}
                open={open}
                anchorEl={anchorEl}
              >
                <Paper>
                  <Grid
                    sx={{ backgroundColor: "#fff", color: "black" }}
                    container
                    padding={2}
                  >
                    {category?.map((item) => {
                      return (
                        <Grid
                          container
                          md={4}
                          justifyContent={"space-around"}
                          textAlign={"left"}
                        >
                          <div onClick={openNavModal}>
                            <List sx={useNavStyle.link} className="link">
                              <Link
                                className="link"
                                to={`/category/${item.id}`}
                              >
                                {item.category}
                              </Link>
                            </List>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Paper>
              </Popper>
              <Grid container md={11} flexWrap={"nowrap"}>
                {!check && (
                  <Grid xs={2} sm={1} md={1}>
                    <IconButton onClick={openNavModal}>
                      {anchorEl ? (
                        <CloseIcon color={"primary"} />
                      ) : (
                        <MenuIcon color={"primary"} />
                      )}
                    </IconButton>
                  </Grid>
                )}
                <Grid
                  container
                  md={12}
                  lg={12}
                  flexWrap={"nowrap"}
                  sx={{ overflowY: "hidden", overflowX: "auto" }}
                >
                  {category?.map((item, index) => {
                    return (
                      <span
                        onClick={() => setActiveIndex(index)}
                        style={{
                          backgroundColor:
                            activeIndex === index ? "#f2f2f2" : "white",
                          borderRadius: "6px",
                        }}
                      >
                        <List sx={useNavStyle.link} className="link">
                          <Link className="link" to={`/category/${item.id}`}>
                            {item.category}
                          </Link>
                        </List>
                      </span>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            {/* {!check ? (
                <>
                  <List sx={useNavStyle.link} className="link">
                    <Link className="link" to="/category/1">
                      Trending
                    </Link>
                  </List>
                  <List sx={useNavStyle.link} className="link">
                    <Link className="link" to="/category/11">
                      Politics
                    </Link>
                  </List>
                  <List sx={useNavStyle.link} className="link">
                    <Link className="link" to="/category/13">
                      Sports
                    </Link>
                  </List>
                  <List sx={useNavStyle.link} className="link">
                    <Link className="link" to="/category/14">
                      World
                    </Link>
                  </List>
                  <List sx={useNavStyle.link} className="link">
                    <Link className="link" to="/category/21">
                      Education
                    </Link>
                  </List>
                </>
              ) : null} */}
            {/* </Grid> */}
          </AppBar>
          <Toolbar disableGutters />
          {!check ? (
            <>
              <Toolbar />
              <Toolbar />
              <Toolbar />
            </>
          ) : (
            <>
              <Toolbar />
            </>
          )}
        </div>
      </>
    );
  }
}

export default Navbar;

import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Category from "../category/Category";
import { apiMethods } from "../../util/ApiConstant";
import { apiEndPoints } from "../../util/ApiConstant";
import { apiCall } from "../../service/ApiHelper";
import { CircularProgress } from "@mui/material";
import { statusCode } from "../../util/ApiConstant";
import CardLayout from "../cardLayout/CardLayout";
import { useParams } from "react-router-dom";

function CategoryShower() {
  const [categoryBlog, setCategoryBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataNotFound, setDataNotFound] = useState(false);
  const { categoryId } = useParams();

  const getCategoryPostById = async () => {
    setDataNotFound(false);
    if (categoryId) {
      let response;
      if (categoryId == "1") {
        response = await apiCall({
          method: apiMethods.GET,
          endPoint: `${apiEndPoints.blogPost}?trending=true`,
        });
      } else {
        response = await apiCall({
          method: apiMethods.GET,
          endPoint: `${apiEndPoints.blogPost}?categoryId=${categoryId}`,
        });
      }
      if (response?.status === statusCode.success) {
        console.log("category response", response.data.data);
        setCategoryBlog(response.data.data ?? []);
        setLoading(false);
      } else if (response?.status === statusCode.not_found) {
        setLoading(false);
        setDataNotFound(true);
      }
    } else {
      setLoading(false);
      setDataNotFound(true);
    }
  };

  useEffect(() => {
    getCategoryPostById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  if (loading) {
    <CircularProgress />;
  } else if (dataNotFound) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography>No Data Found</Typography>
      </Grid>
    );
  } else {
    return (
      <>
        <Category
          arrow={true}
          type={categoryBlog[0]?.category?.category ?? ""}
        />
        <Grid
          container
          gap={3}
          xs={12}
          md={12}
          mt={2}
          justifyContent={"space-evenly"}
        >
          <Grid
            container
            justifyContent={"space-between"}
            xs={12}
            md={12}
            sx={{ marginBottom: "20px" }}
          >
            {categoryBlog &&
              categoryBlog.map((d) => {
                return (
                  <Grid md={5.5}>
                    <CardLayout
                      url={d.post_contents[0]?.photoURL}
                      content={d.title}
                      id={d.post_contents[0]?.postId}
                      publishDate={d.createdAt}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default CategoryShower;

import React, { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  Grid,
  TextField,
  createFilterOptions,
  Button,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { apiCall, getAuthToken } from "../../service/ApiHelper";
import { apiEndPoints } from "../../util/ApiConstant";
import { apiMethods } from "../../util/ApiConstant";
import { statusCode } from "../../util/ApiConstant";
import { toast } from "react-toastify";
import "./CreateBlog.css";
import AddIcon from "@mui/icons-material/Add";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";

function CreateBlog() {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [tag, setTag] = useState([]);
  const [tagId, setTagId] = useState([]);
  const [url, setUrl] = useState([]);
  const [newsContent, setNewsContent] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [addMoreData, setAddMore] = useState([nanoid()]);
  const firstRender = useRef(true);

  const navigate = useNavigate();

  const getCategory = async () => {
    setLoading(true);
    const response = await apiCall({
      method: apiMethods.GET,
      endPoint: apiEndPoints.category,
    });
    if (response?.status === statusCode.success) {
      setCategory(response?.data?.category ?? []);
      setLoading(false);
    }
  };

  const getTags = async () => {
    setLoading(true);
    const response = await apiCall({
      method: apiMethods.GET,
      endPoint: apiEndPoints.tags,
    });
    if (response?.status === statusCode.success) {
      setTag(response?.data?.category ?? []);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      if (!getAuthToken()) {
        navigate("/admin");
      } else {
        getCategory();
        getTags();
        firstRender.current = false;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleImageUrl = (e, index) => {
    const clonedUrl = [...url];
    clonedUrl[index] = e.target.value;
    setUrl(clonedUrl);
  };

  const handleCreateCategory = async (value) => {
    setLoading(true);
    try {
      const response = await apiCall({
        method: apiMethods.POST,
        endPoint: apiEndPoints.category,
        data: { categoryName: value },
      });
      if (response?.status === statusCode.inserted) {
        getCategory();
        setCategoryId({
          id: response?.data?.data?.id,
          category: response?.data?.data?.category,
        });
        setLoading(false);
        toast.success("Category is Created");
      }
    } catch (error) {
      toast.error("Error Occur when Category is created");
    }
  };

  const handleCategoryChange = (e, newValue) => {
    if (newValue?.newInstance) {
      handleCreateCategory(newValue.category);
    } else if (newValue !== null) {
      setCategoryId(newValue);
    }
  };

  const handleCreateTag = async (value) => {
    setLoading(true);
    try {
      const response = await apiCall({
        method: apiMethods.POST,
        endPoint: apiEndPoints.tags,
        data: { tageName: value },
      });
      if (response?.status === statusCode.inserted) {
        getTags();
        setTagId([
          ...tagId,
          {
            id: response?.data?.data?.id,
            tag: response?.data?.data?.tag,
          },
        ]);
        setLoading(false);
        toast.success("Tag is Created");
      }
    } catch (error) {
      console.error("Error creating Tag:", error);
    }
  };

  const handleTagChange = (e, newValue) => {
    if (newValue[newValue?.length - 1]?.newInstance) {
      handleCreateTag(newValue[newValue.length - 1].tag);
    } else if (newValue !== null) {
      setTagId(newValue);
    }
  };

  const handleContent = (e, index) => {
    const clonedNewsContent = [...newsContent];
    clonedNewsContent[index] = e.target.value;
    setNewsContent(clonedNewsContent);
  };

  const filterCategory = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.category,
  });

  const filterTag = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.tag,
  });

  const postContentFormatter = () => {
    let formattedArray = [];
    for (let i = 0; i < addMoreData.length; i++) {
      if (
        (newsContent[i] && url[i] && newsContent[i] !== "") ||
        url[i] !== ""
      ) {
        formattedArray = [
          ...formattedArray,
          { url: url[i] ?? "", content: newsContent[i] ?? "" },
        ];
      }
    }
    return formattedArray;
  };

  const handleCreatePost = async () => {
    if (!title && categoryId && tagId?.length > 0 && !url && !newsContent) {
      setError("Fill Above Details");
    } else if (!title) {
      setError("Enter news title");
    } else if (!categoryId) {
      setError("Choose Category");
    } else if (!tagId?.length > 0) {
      setError("Choose Tags");
    } else if (!url) {
      setError("Enter Image Url");
    } else if (!newsContent) {
      setError("Enter News Content");
    } else {
      setError("");
      const response = await apiCall({
        method: apiMethods.POST,
        endPoint: `${apiEndPoints.blogPost}`,
        data: {
          title,
          categoryId: categoryId.id,
          tags: tagId.map((data) => {
            return { id: data.id };
          }),
          trending:checked,
          postContents: postContentFormatter(),
        },
      });
      if (response?.status === statusCode.inserted) {
        toast.success("Post is Created");
        setCategory("");
        setCategoryId();
        setTag([]);
        setTitle("");
        setTagId([]);
        setUrl([]);
        setNewsContent([]);
        setAddMore([nanoid()]);
      }
    }
  };

  const toggleCheckedHandler = () => {
    setChecked(!checked);
  };

  const addMoreHandler = () => {
    setAddMore([...addMoreData, nanoid()]);
  };

  if (loading) {
    <CircularProgress />;
  }

  return (
    <Grid container flexDirection={"column"} alignContent={"center"} alignItems={"center"} sx={{backgroundColor:"#e0e0e0",minHeight:"100vh"}}>
      <h1>Create Post</h1>
      <Grid container flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={handleTitle}
          margin="normal"
          sx={{ borderRadius: "5px", width: "50%"}}
        />
        <Grid
          container
          sx={{ width: "50%" }}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Autocomplete
            value={categoryId}
            onChange={handleCategoryChange}
            filterOptions={(options, params) => {
              const filtered = filterCategory(options, params);

              if (params.inputValue !== "") {
                filtered?.push({
                  category: `${params.inputValue}`,
                  newInstance: true,
                });
              }
              return filtered;
            }}
            options={category}
            getOptionLabel={(option) => option.category}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Choose Category" />
            )}
            isOptionEqualToValue={(option, value) =>
              option.category === value.category
            }
            renderOption={(props, option) => (
              <li
                key={option.contegory}
                {...props}
                style={{
                  wordWrap: "break-word",
                }}
              >
                {!option?.id
                  ? "click to add new " + option.category
                  : option.category}
              </li>
            )}
          />
          <div style={{margin:"10px 0 10px 0"}}>
            <Typography>Trending</Typography>
            <label className="switch">
              <input
                onClick={toggleCheckedHandler}
                type="checkbox"
                checked={checked}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <Autocomplete
            multiple
            value={tagId}
            onChange={handleTagChange}
            filterOptions={(options, params) => {
              const filtered = filterTag(options, params);

              if (params.inputValue !== "") {
                filtered?.push({
                  tag: `${params.inputValue}`,
                  newInstance: true,
                });
              }
              return filtered;
            }}
            options={tag}
            getOptionLabel={(option) => option.tag}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Add Tags" />}
            isOptionEqualToValue={(option, value) => option.tag === value.tag}
            renderOption={(props, option) => (
              <li
                key={option.tag}
                {...props}
                style={{
                  wordWrap: "break-word",
                }}
              >
                {!option?.id ? "click to add new " + option.tag : option.tag}
              </li>
            )}
          />
        </Grid>
        {addMoreData.map((dynamicId, index) => (
          <Grid key={dynamicId} width={"inherit"} container flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            <TextField
              label="Image URL"
              variant="outlined"
              value={url[index]}
              fullWidth
              onChange={(e) => handleImageUrl(e, index)}
              margin="normal"
              sx={{
                borderRadius: "5px",
                width: "50%",
              }}
            />
            <br />
            {/* <TextareaAutosize
              value={newsContent[index]}
              onChange={(e) => handleContent(e, index)}
              minRows={5}
              placeholder="Enter News Content"
              sx={{ minWidth: "50%" }}
            /> */}
            <TextField
             label="Enter news Content"
             variant="outlined"
             value={newsContent[index]}
             onChange={(e) => handleContent(e, index)}
             margin="normal"
             multiline
             rows={7}
             sx={{
               borderRadius: "5px",
               width: "50%",
             }}/>
          </Grid>
        ))}
        <IconButton onClick={addMoreHandler}>
          <AddIcon />
        </IconButton>
      </Grid>
      {error && (
        <Typography sx={{ color: "red", fontSize: "15px" }}>{error}</Typography>
      )}
      <Button
        variant="contained"
        sx={{ backgroundColor: "#ffa69e", textTransform: "none"}}
        onClick={handleCreatePost}
      >
        Create Post
      </Button>
    </Grid>
  );
}

export default CreateBlog;

import React from "react";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  PinterestIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { WhatsappIcon, FacebookIcon, TwitterIcon } from "react-share";
import { Grid } from "@mui/material";
const baseUrl = "https://maayamnews.com/blog-post/";
function ShareUi({ title, url }) {
  return (
    <Grid container gap={2}>
      <WhatsappShareButton url={`${baseUrl}${url}`} title={title}>
        <WhatsappIcon round size={30} />
      </WhatsappShareButton>
      <FacebookShareButton url={`${baseUrl}${url}`} title={title}>
        <FacebookIcon round size={30} />
      </FacebookShareButton>
      <TwitterShareButton url={`${baseUrl}${url}`} title={title}>
        <TwitterIcon round size={30} />
      </TwitterShareButton>
      <PinterestShareButton url={`${baseUrl}${url}`} title={title}>
        <PinterestIcon round size={30} />
      </PinterestShareButton>
      <LinkedinShareButton url={`${baseUrl}${url}`} title={title}>
        <LinkedinIcon round size={30} />
      </LinkedinShareButton>
      <EmailShareButton url={`${baseUrl}${url}`} title={title}>
        <EmailIcon round size={30} />
      </EmailShareButton>
    </Grid>
  );
}

export default ShareUi;

import React from "react";
import Navbar from "../navbar/Navbar";
import { Grid, useMediaQuery } from "@mui/material";
import Footer from "../footer/Footer";

function General({ children }) {
  let checkIsMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <Grid width={checkIsMobile ? "100%" : "60%"} margin={"auto"}>
        {children}
        <Footer />
      </Grid>
    </div>
  );
}

export default General;

import React from "react";
import { Grid, Typography } from "@mui/material";

function PrivacyPolicy() {
  return (
    <Grid sx={{ padding: "20px" }}>
      <Typography variant="h5">Privacy Policy</Typography>
      <br />
      <br />
      <Typography>
        Maayam news is committed to protecting the privacy and confidentiality
        of our viewers and visitors to our website. This privacy policy outlines
        the types of personal information we collect, how it is used, and the
        steps we take to safeguard it.
        <br />
        <br />
        <Typography variant="h5">Information We Collect:</Typography>
        <br />
        Personal Information: When you interact with Maayam news, we may collect
        personal information such as your name, email address, postal address,
        and phone number. This information is collected voluntarily when you
        subscribe to our newsletter, participate in surveys or contests, or
        contact us for inquiries or feedback. Usage Information: We
        automatically collect certain information about your interactions with
        our website, such as your IP address, browser type, device identifiers,
        and pages visited. This information is collected using cookies and
        similar technologies for analytics and website optimization purposes.
        <br />
        <br />
        <Typography variant="h5">How We Use Your Information:</Typography>
        <br />
        To Provide Services: We use the information we collect to deliver the
        services you request, such as sending newsletters, processing contest
        entries, and responding to inquiries. To Improve Our Content: We analyze
        usage data to understand how our audience interacts with our content and
        to improve the relevance and quality of our news coverage and website
        features. For Marketing and Communication: With your consent, we may use
        your contact information to send promotional emails, updates, and other
        communications about [Your News Channel] and related products or
        services. You can opt out of receiving marketing communications at any
        time.
        <br />
        <br />
        <Typography variant="h5">Data Sharing and Disclosure:</Typography>
        <br />
        We do not sell, trade, or rent your personal information to third
        parties for marketing purposes. We may share your information with
        trusted service providers who assist us in operating our website,
        conducting business, or servicing you, as long as those parties agree to
        keep this information confidential. We may also disclose your
        information when we believe it is necessary to comply with the law,
        enforce our site policies, or protect our or others' rights, property,
        or safety.
        <br />
        <br />
        <Typography variant="h5">Data Security:</Typography>
        <br />
        We implement a variety of security measures to maintain the safety and
        confidentiality of your personal information. These measures include
        encryption, access controls, and regular security assessments.
        <br />
        <br />
        <Typography variant="h5">Your Rights:</Typography>
        <br />
        You have the right to access, update, or delete your personal
        information. If you would like to exercise these rights or have any
        questions about our privacy practices, please contact us using the
        information provided below.
        <br />
        <br />
        <Typography variant="h5">Changes to This Privacy Policy:</Typography>
        <br />
        We reserve the right to update or modify this privacy policy at any
        time. Any changes will be reflected on this page, and we encourage you
        to review this policy periodically for updates.
      </Typography>
    </Grid>
  );
}

export default PrivacyPolicy;

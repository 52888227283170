import React, { useState } from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "./AdminLogin.css";
import { apiMethods } from "../../util/ApiConstant";
import { apiEndPoints } from "../../util/ApiConstant";
import { apiCall } from "../../service/ApiHelper";
import { statusCode } from "../../util/ApiConstant";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    if (!email && !password) {
      setError("Enter Email and Password");
    } else if (!email) {
      setError("Enter Email");
    } else if (!password) {
      setError("Enter Password");
    } else {
      setError(null);
      const response = await apiCall({
        method: apiMethods.POST,
        endPoint: `${apiEndPoints.login}`,
        data: {
          email,
          password,
        },
      });
      if (response?.status === statusCode.success) {
        Cookies.set("news_token", response?.data?.data?.token);
        navigate("/create-blog");
      }
    }
  };

  return (
    <Grid container height={"100dvh"} alignItems={"center"}>
      <Grid container justifyContent={"center"} className="loginWrapper">
        <h1 style={{ color: "#ffa69e", display: "inline-block" }}>
          Admin Login
        </h1>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          margin="normal"
          sx={{ backgroundColor: "#93e1d8", borderRadius: "5px" }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          margin="normal"
          sx={{ backgroundColor: "#93e1d8", borderRadius: "5px" }}
        />
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
          <Button
            variant="contained"
            sx={{ backgroundColor: "#ffa69e", textTransform: "none" }}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Typography sx={{ fontSize: "16px", marginTop: "5px" }}>
            <Link style={{ textDecoration: "none" }}>Forget Password?</Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdminLogin;

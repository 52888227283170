import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, Typography, Grid, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CardLayout({ url, content, id, publishDate, width }) {
  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate(`/blog-post/${id}`);
  };
  const date = new Date(publishDate);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  const formattedDate = date.toLocaleString("en-IN", options);
  let checkIsMobile = useMediaQuery("(max-width:600px)");

  return (
    <div style={{ marginBottom: "5px", cursor: "pointer" }}>
      <CardActionArea onClick={navigateHandler}>
        <Card elevation={0} sx={{ backgroundColor: "white" }}>
          <Grid
            container
            sm={12}
            md={12}
            sx={
              checkIsMobile && {
                margin: "0px",
                width: width ?? "100vw",
                padding: "10px",
                flexDirection: "column",
              }
            }
          >
            <Grid xs={12} sm={12} md={3}>
              <CardMedia
                component="img"
                sx={{ Width: 100, objectFit: "contain" }}
                image={url}
                alt="Live from space album cover"
              />
            </Grid>
            <Grid xs={12} sm={12} md={9}>
              <CardContent sx={{ padding: 0 }}>
                <Typography marginTop="70px">{content}</Typography>
                <Grid sx={{ marginTop: "10px" }}>
                  <Typography sx={{ color: "#4f4f4f", fontSize: "12px" }}>
                    Published:{" "}
                    <span style={{ color: "#949494", fontSize: "12px" }}>
                      {formattedDate}
                    </span>
                  </Typography>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </CardActionArea>
      {/* <Divider /> */}
    </div>
  );
}

export default CardLayout;
